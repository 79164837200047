<template>
  <div>
    <b-card>
      <b-card-title style="margin-bottom: 0px">
        <b-row>
          <b-col> Today's Menu </b-col>
          <b-col cols="auto">
            <b-form-file
            placeholder="Upload a menu..."
              id="file-doc"
              @input="onPresentationFileSelected"
              accept=".xlsx"
              style="width: 240px; margin-left: 30px"
            ></b-form-file>
          </b-col>
          <b-button
                  variant="primary"
                  @click="sendMenu"
                  v-if="getMenuAll"
                >
                  <span class="text-nowrap">Upload</span>
                </b-button>
        </b-row>
      </b-card-title>
      <br/>

    
      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="getMenuAll"
          responsive
          :fields="tableColumns"
          primary-key="id"
          
          show-empty
          empty-text="No matching records found"
    
        >
  
          <!-- Column: User -->
          <template #cell(date)="data">
            <b-media vertical-align="center">
              
                
                {{ data.item.date  | formattedDate    }}  
           
              
            </b-media>
          </template>
  
          
  
          <!-- Column: Status -->
          <template #cell(menu)="data">
             
     
            
            <span >

            
            </span>
            <b-media vertical-align="center"  v-for="menuitem in   data.item.menu " v-bind:key="menuitem.meal">
            <template #aside>
              
            </template>
            <b-link
               class="font-weight-bold d-block text-nowrap"
            >
            {{ menuitem.meal }} 
            </b-link>
              <small class="text-muted"> {{ menuitem.calories }} </small>
        
          </b-media>
            
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
             <b-button
                  variant=".btn-flat-dark"
                  @click="changeCategory(data.item)"
                >
                Edit
               </b-button>
          </template>
  
        </b-table>
  
    </b-card>
  </div>
</template>
<script>
import ExcelImport from "./tools/ExcelImport";
import { ref, computed,onUnmounted } from "@vue/composition-api";
import store from "@/store";
import VMealItem from "./components/VMealItem.vue";

import {
  BFormFile,
  BCard,
  BCardBody,
  BCardFooter,
  BCardTitle,
  BCol,
  BRow,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
} from "bootstrap-vue";
import moment from 'moment'
import userStoreModule from './dailyMenuStoreModule' 
export default {
  components: {
    BFormFile,
    VMealItem,
    BCard,
    BCardBody,
    BCardFooter,
    BCardTitle,
      BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  },
  filters: {
        formattedDate: function (value) {
          if (value) {
        return moment(String(value)).format('DD/MM/YYYY dddd')
    }
        }
    },
    methods:{
    moment: function () {
    return moment();
  },
},
  setup() {
    let onPresentationFileSelected = async function (input) {
      let menuData = await ExcelImport.Excel2Json(input);
      store.dispatch("hr-menu/getImportedMenu", menuData);
    };

    const USER_APP_STORE_MODULE_NAME = 'app-daily-menu'

// Register module
if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

// UnRegister on leave
onUnmounted(() => {
  if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
})


    const getMenuToday = computed(() => {
      //this part going to change when endpoints come-up

      let monthlyList = store.getters["hr-menu/getImportedMenu"];
       console.log(monthlyList);
      if (monthlyList != null) {
        let today = new Date();
        let todayMeal = monthlyList.filter(
          (x) => x.date.getDate() == today.getDate()
        );
    if (todayMeal==null) todayMeal=[];

        todayMeal[0].menu = todayMeal[0].menu.filter(
          (x) => x.meal != undefined
        );
        return todayMeal[0].menu;
      } else {
        return null;
      }
    });
    const getMenuAll = computed(() => {
      //this part going to change when endpoints come-up

      let monthlyList = store.getters["hr-menu/getImportedMenu"];
     
     return monthlyList;

      console.log(monthlyList);
      if (monthlyList != null) {
        let today = new Date();
        let todayMeal = monthlyList.filter(
          (x) => x.date.getDate() == today.getDate()
        );
    if (todayMeal==null) todayMeal=[];

        todayMeal[0].menu = todayMeal[0].menu.filter(
          (x) => x.meal != undefined
        );
        return todayMeal[0].menu;
      } else {
        return null;
      }
    });

  const  tableColumns = [
    { key: 'date', sortable: true },
    
   
     
    { key: 'menu',   label:"Category", sortable: true },
     
  ]
  const sendMenu=()=>{
    let monthlyList = store.getters["hr-menu/getImportedMenu"];
     
     
  console.log("menu log ",monthlyList )

  var data={repast:1,menuList:monthlyList};

  store
      .dispatch('app-daily-menu/uploadMenu',data)
      .then(response => {
        const { data } = response.data
        console.log(response.data);
         
        callback(data)
        totalUsers.value = data.length;

      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
    return {
      sendMenu,
      tableColumns,
      onPresentationFileSelected,
      getMenuToday,
      getMenuAll
    };
  },
};
</script>
<style></style>
